<template>
    <v-stepper v-model="step">
        <v-stepper-header>
            <v-stepper-step
                :complete="step > 1"
                step="1"
                :editable="id !== null"
            >Basic Config</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
                :complete="step > 2"
                step="2"
                :editable="id !== null"
            >Articles</v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
            <v-stepper-content step="1">
                <v-form ref="formBasic" v-model="formBasicValid">
                    <v-row>
                        <v-col>
                            <v-text-field
                                label="Application Title"
                                v-model="inputs.title"
                                :rules="[rules.required, rules.counter]"
                                outlined
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-menu
                                v-model="menu.start_date"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="menu.start_date_formatted"
                                        label="Start Date"
                                        hint="MM/DD/YYYY format"
                                        persistent-hint
                                        prepend-icon="fa-calendar-alt"
                                        @blur="inputs.start_date = dateService.parseDate(menu.start_date_formatted)"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="inputs.start_date"
                                    no-title
                                    @input="menu.start_date = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col>
                            <v-menu
                                v-model="menu.end_date"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="menu.end_date_formatted"
                                        label="End Date"
                                        hint="MM/DD/YYYY format"
                                        persistent-hint
                                        prepend-icon="fa-calendar-alt"
                                        @blur="inputs.end_date = dateService.parseDate(menu.end_date_formatted)"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="inputs.end_date"
                                    no-title
                                    @input="menu.end_date = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-btn text :to="'/dashboard'">Cancel</v-btn>
                        </v-col>
                        <v-col>
                            <v-btn
                                color="primary"
                                @click="saveBasic"
                                :loading="isSavingBasic"
                            >{{ id !== null ? 'Save & Continue' : 'Save' }}</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-stepper-content>

            <v-stepper-content step="2">
                <v-card class="pl-5 pr-5">
                    <v-form ref="formField" v-model="formArticleValid">
                        <v-card-text>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        label="Title"
                                        v-model="article.title"
                                        :rules="[rules.required]"
                                    ></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                        label="Category"
                                        v-model="article.category"
                                        :rules="[rules.required]"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-text-field
                                    label="Article URL"
                                    v-model="article.url"
                                    :rules="[rules.url]"
                                ></v-text-field>
                            </v-row>
                            <v-row>
                                <v-text-field
                                    label="Image URL"
                                    v-model="article.image"
                                    :rules="[rules.url]"
                                ></v-text-field>
                            </v-row>
                            <v-row align="center" justify="center">
                                <v-col>
                                    <v-menu
                                        v-model="menu.article_date"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                v-model="menu.article_date_formatted"
                                                label="Date"
                                                hint="MM/DD/YYYY format"
                                                persistent-hint
                                                prepend-icon="fa-calendar-alt"
                                                @blur="article.date = dateService.parseDate(menu.article_date_formatted)"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="article.date"
                                            no-title
                                            @input="menu.article_date = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col>
                                    <v-btn
                                        color="primary"
                                        @click="addArticle"
                                        :loading="isSavingArticle"
                                    >Add</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-form>
                </v-card>

                <br>
                <v-divider light></v-divider>
                <br>

                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-center">TITLE</th>
                                <th class="text-center">CATEGORY</th>
                                <th class="text-center">URL</th>
                                <th class="text-center">IMAGE</th>
                                <th class="text-center">DATE</th>
                                <th class="text-center">ACTIONS</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(tempArticle, tempArticleIndex) in tempArticles" :key="tempArticleIndex">
                                <td class="text-center">{{ tempArticle.title }}</td>
                                <td class="text-center">{{ tempArticle.category }}</td>
                                <td class="text-center">{{ tempArticle.url }}</td>
                                <td class="text-center">{{ tempArticle.thumbnail }}</td>
                                <td class="text-center">{{ tempArticle.date }}</td>
                                <td class="text-center">
                                    <v-btn
                                        @click.native="deleteArticle(tempArticle.id)"
                                        icon="icon"
                                    ><v-icon class="text-info">fa-trash-alt</v-icon></v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>

                <v-row align="center" justify="start">
                    <v-col>
                        <v-btn text @click="step = 1">Back</v-btn>
                    </v-col>
                </v-row>
            </v-stepper-content>
        </v-stepper-items>
    </v-stepper>
</template>

<script>
    import _ from 'lodash';
    import DateService from '@/services/date.service';

    export default {

        name: 'Edit',

        props: ['id', 'data'],

        data: () => ({
            step: 1,
            dateService: DateService,
            formBasicValid: true,
            formArticleValid: true,
            inputs: {
                title: '',
                start_date: new Date().toISOString().substr(0, 10),
                end_date: DateService.addDays(new Date(), 15).toISOString().substr(0, 10)
            },
            menu: {
                start_date: false,
                start_date_formatted: DateService.formatDate(new Date().toISOString().substr(0, 10)),
                end_date: false,
                end_date_formatted: DateService.formatDate(DateService.addDays(new Date(), 15).toISOString().substr(0, 10)),
                article_date : false,
                article_date_formatted: DateService.formatDate(DateService.addDays(new Date(), 15).toISOString().substr(0, 10)),
            },
            rules: {
                required: value => !!value || 'Required',
                counter: value => value.length >= 5 || 'Min 5 characters',
                url: value => {
                    const pattern = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
                    return pattern.test(value) || 'Invalid url';
                }
            },
            article: {
                title: '',
                category: '',
                url: '',
                image: '',
                date: new Date().toISOString().substr(0, 10)
            },
            tempArticles: [],
            isSavingBasic: false,
            isSavingArticle: false
        }),

        computed: {

            articles() {

                return this.id !== null ? this.$store.getters['feedcreator/articles'] : {};
            }
        },

        watch: {
            'inputs.start_date'() {
                this.menu.start_date_formatted = DateService.formatDate(this.inputs.start_date);
            },

            'inputs.end_date'() {
                this.menu.end_date_formatted = DateService.formatDate(this.inputs.end_date);
            },

            'article.date'() {
                this.menu.article_date_formatted = DateService.formatDate(this.article.date);
            }
        },

        mounted() {
            this.isSavingBasic = false;
            this.isSavingArticle = false;

            if (localStorage.getItem('flowUpdate') !== null) {
                this.step = 2;
                localStorage.removeItem('flowUpdate');
            }

            if (this.id !== null) {
                this.setInputValues();
            }
        },

        methods: {

            setInputValues() {
                _.each(this.inputs, (v, k) => {
                    if (k.indexOf('date') > -1) {
                        this.inputs[k] = new Date(this.data[k]).toISOString().substr(0, 10);
                    } else {
                        this.inputs[k] = this.data[k];
                    }
                });

                this.tempArticles = _.cloneDeep(this.articles);
            },

            saveBasic() {
                this.isSavingBasic = true;
                this.$refs.formBasic.validate();

                if (this.formBasicValid) {
                    const action = this.id === null ? 'new' : 'update';
                    const inputs = _.cloneDeep(this.inputs);
                    inputs.start_date += ' 00:00:00';
                    inputs.end_date += ' 23:59:59';
                    const data = this.id === null ? inputs : {
                        id: this.id,
                        data: inputs
                    };

                    this.$store.dispatch('feedcreator/' + action, data).then(response => {
                        this.$store.dispatch('feedcreator/get', response.id).then(() => {
                            this.isSavingBasic = false;

                            if (action === 'new') {
                                localStorage.setItem('flowUpdate', true);
                                this.$router.replace({
                                    name: 'feedcreator',
                                    params: {
                                        id: response.id,
                                        selectedTab: 'edit'
                                    }
                                });
                            } else {
                                this.step = 2;
                            }
                        }).catch(() => {});
                    }).catch(() => {});
                } else {
                    this.isSavingBasic = false;
                }
            },

            addArticle() {
                this.isSavingArticle = true;
                this.$refs.formField.validate();

                if (this.formArticleValid) {
                    const article = _.cloneDeep(this.article);

                    this.$store.dispatch('feedcreator/newArticle', {
                        id: this.id,
                        article
                    }).then(() => {
                        this.$store.dispatch('feedcreator/getArticles', this.id).then(() => {
                            this.tempArticles = _.cloneDeep(this.articles);
                            this.$refs.formField.reset();
                            this.isSavingArticle = false;
                        }).catch(() => {});
                    }).catch(() => {});
                } else {
                    this.isSavingArticle = false;
                }
            },

            deleteArticle(articleId) {
                this.$store.dispatch('feedcreator/deleteArticle', {
                    id: this.id,
                    articleId
                }).then(() => {
                    this.$store.dispatch('feedcreator/getArticles', this.id).then(() => {
                        this.tempArticles = _.cloneDeep(this.articles);
                    }).catch(() => {});
                }).catch(() => {});
            }
        }
    }
</script>

<style lang="scss">
    .hidden-textarea {
        color: red;

        .v-input__slot {
            display: none !important;
        }
    }
</style>
